import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Project Architecture'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Project Architecture`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Architecture specifications and guidelines are still in definition.`}</p>
    </blockquote>
    <h2>{`Conventions`}</h2>
    <p><a parentName="p" {...{
        "href": "http://editorconfig.org"
      }}>{`editorconfig`}</a>{` is used for common file conventions.`}</p>
    <p><a parentName="p" {...{
        "href": "https://standardjs.com"
      }}>{`standardjs`}</a>{` style guide is used with minor tweaks with
`}<a parentName="p" {...{
        "href": "https://eslint.org"
      }}>{`ESLint`}</a>{`.`}</p>
    <p>{`Please install in your editor/IDE the respective plugins/packages.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      